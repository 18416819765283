import React, { Component } from 'react';
import PropTypes from "prop-types";
import { css } from 'glamor';

const ThemeContext = React.createContext();

class ThemeProvider extends Component {
    buildButtonTheme(theme) {
        let buttonTheme = {
            color: theme.textColor || 'white',
            backgroundColor: theme.fill || 'transparent',
            borderColor: theme.border || 'transparent',
            ':hover': {
                color: theme.textColorOnHover,
                backgroundColor: theme.fillOnHover,
                borderColor: theme.borderOnHover
            }
        };
        return buttonTheme;
    }
    
    buildTheme(theme) {
        let themeSettings = {
            title: css({
                color: theme.title
            }),
            description: css({
                color: theme.description,
                'a': {
                    color: theme.link,
                    ':hover': {
                        color: theme.linkOnHover
                    }
                }
            }),
            link: css({
                color: theme.link,
                ':hover': {
                    color: theme.linkOnHover
                }
            }),
            menu: css({
                color: theme.menu,
                ':hover': {
                    color: theme.menuOnHover
                },
                ':active': {
                    color: theme.activeMenu
                }
            }),
            activeMenu: css({
                color: theme.activeMenu
            }),
            buttonPrimary: css(this.buildButtonTheme(theme.buttonPrimary)),
            buttonSecondary: css(this.buildButtonTheme(theme.buttonSecondary)),
            buttonLight: css(this.buildButtonTheme(theme.buttonLight)),
            buttonDark: css(this.buildButtonTheme(theme.buttonDark)),
        };
        
        return themeSettings;
    }

    render() {
        const { children, theme } = this.props;
        const userDefinedTheme = this.buildTheme(theme);

        return (
            <ThemeContext.Provider value={userDefinedTheme}>
                {children}
            </ThemeContext.Provider>
        )
    }
}

ThemeProvider.defaultProps = {
    theme: {}
}

ThemeProvider.propTypes = {
    theme: PropTypes.object
}

export { ThemeContext, ThemeProvider };